import React from "react";
import { PageProps, graphql } from "gatsby";

import { Query } from "@graphql-types";
import { Hero } from "@global";
import Layout from "@shared/layout";
import SEO from "@shared/seo";
import JobListings from "@components/careers/jobListings";

interface Props extends PageProps {
  data: Query;
}

export default function CareersPage({ data }: Props) {
  if (data.sanityCareersPage == null) {
    return null;
  }

  const { seo, hero, jobListings } = data.sanityCareersPage;

  return (
    <>
      <SEO seoData={seo} />
      <Layout>
        <Hero data={hero} />
        {jobListings && <JobListings data={jobListings} />}
      </Layout>
    </>
  );
}

export const query = graphql`
  {
    sanityCareersPage {
      seo {
        ...sanitySeo
      }
      hero {
        ...sanityHero
      }
      jobListings {
        _key
        _type
        title
        link {
          ...sanityLink
        }
        TextContent {
          ...sanityBlockContent
        }
      }
    }
  }
`;
